$.fn.enterKey = function (fnc, mod) {
    return this.each(function () {
        $(this).keypress(function (ev) {
            var keycode = (ev.keyCode ? ev.keyCode : ev.which);
            if ((keycode == '13' || keycode == '10') && (!mod || ev[mod + 'Key'])) {
                fnc.call(this, ev);
            }
        })
        $(this).change(function(ev) {
          fnc.call(this, ev);
        })
    })
}
$('.answeropts').each(function() {
    var origin = $(this);
    var $sbm = origin.closest('form').find('[type="submit"]');
    origin.hide();
    var ul = $('<ul class="answeropts-list"></ul>');
    var input = $('<input type="text" class="answeropts-input" placeholder="Typ een nieuwe optie, en druk op enter..">');

    input.enterKey(function(event) {
        var val = input.val().replace(/;/g, "");
        input.val('');
        if (val != '') {
           ul.append('<li class="answeropts-list-item"><span>' + val + '</span><div>verwijder</div></li>');
        }
        ul.trigger('change');
        event.preventDefault();
    });

    origin.change(function() {
       var split = $(this).val().split(';');
          ul.html('');
        for (var i in split) {
             if (split[i] != '')
             ul.append('<li class="answeropts-list-item"><span>' + split[i] + '</span><div>verwijder</div></li>');
        }
    });

    ul.change(function() {
       var array = [];
        $('li',ul).each(function() {
           array.push($('span',this).text());
        });
       origin.val(array.join(';'));
    });

    ul.on('click','li div',function() {
      $sbm.prop('disabled', true);
       $(this).parent().slideUp(500,function() {
        $(this).remove();
        ul.trigger('change');
        $sbm.prop('disabled', false);
      });
    });

    $(this).after(input).after(ul);

    origin.trigger('change');
});


$('a[data-toggle="tab"][href="'+window.location.hash+'"]').tab('show');



$(document).on('click', '[data-family-id]', function() {
   var id = $(this).data('family-id');
    window.location.href = '/familys/'+id;
});




        $(function () {
            $('[data-toggle="tooltip"]').tooltip().css('cursor', 'default')
        });
